import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomTableColumnType } from 'src/app/components/custom-table/enums/custom-table-column-type.enum';
import { CustomTableColumn } from 'src/app/components/custom-table/interfaces/custom-table-column';
import { CustomTableStructure } from 'src/app/components/custom-table/interfaces/custom-table-structure';
import { PayslipPeriod } from 'src/app/models/payslip-period';
import { ApiPayslipsService } from 'src/app/services/api-payslips.service';
import { UserInterfaceService } from 'src/app/services/user-interface.service';

@Component({
  selector: 'app-payslips-periods-list',
  templateUrl: './payslips-periods-list.component.html',
  styleUrls: ['./payslips-periods-list.component.scss']
})
export class PayslipsPeriodsListComponent implements OnInit {

  periods: PayslipPeriod[] = [];

  dataLoaded: boolean = false;

  columnsTable: CustomTableColumn[] = [
    { name: 'year', text: 'Año', type: CustomTableColumnType.TEXT },
    { name: 'month', text: 'Mes', type: CustomTableColumnType.TEXT },
    { name: 'name', text: 'Nombre', type: CustomTableColumnType.TEXT }];

  periodsTable: CustomTableStructure;

  constructor(private APIPayslips: ApiPayslipsService,
    private ui: UserInterfaceService,
  private router:Router) { }

  ngOnInit(): void {
    this.getPeriods();
  }

  new() {
    this.router.navigate(['payslips-periods-form']);
  }

  goToEdit(periodId: number) {
    this.router.navigate(['payslips-periods-form', periodId]);
  }

  getPeriods() {
    this.ui.spin$.next(true);
    this.APIPayslips.getPayslipsPeriods().subscribe(response => {
      this.periods = response.data;

      this.periodsTable = { columns: this.columnsTable, data: this.periods };

      this.dataLoaded = true;
      this.ui.spin$.next(false);
    });
  }

  delete(periodId: number) {
    this.APIPayslips.deletePeriod(periodId).subscribe(response => {
      this.getPeriods();
    });
  }

}
