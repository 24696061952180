import { Injectable } from '@angular/core';
import { ApiBaseService } from './api-base.service';
import { HttpClient } from '@angular/common/http';
import { HttpBaseResponse } from '../models/base-response';
import { PayslipPeriod } from '../models/payslip-period';
import { PayslipPeriodRequest } from '../models/payslip-period-request';
import { PayslipRequest } from '../models/payslip-request';

@Injectable({
  providedIn: 'root'
})
export class ApiPayslipsService extends ApiBaseService {

  constructor(private http: HttpClient) {
    super();
    this._controller = "payslips";
  }

  /**
    * @ngdoc function
    * @name getPayslipsPeriods
    * @param cityId number
    * @methodOf ApiPayslipsService
    * @description Se obtiene la información de los períodos de recibos de sueldo.
    * @return HttpBaseResponse
    */

  getPayslipsPeriods(periodId?: number) {
    let endpoint = `${this._apiUrl}/${this._controller}/payslips_periods`;
    if (periodId) endpoint += `/${periodId}`;

    return this.http.get<HttpBaseResponse>(endpoint);
  }

  /**
    * @ngdoc function
    * @name getPayslips
    * @param userId number
    * @param paySlipId number
    * @methodOf ApiPayslipsService
    * @description Se obtiene la información de los recibos de sueldo.
    * @return HttpBaseResponse
    */

  getPayslips(userId?: number, paySlipId?: number) {
    let endpoint = `${this._apiUrl}/${this._controller}/payslips`;
    if (userId) endpoint += `/${userId}`;
    if (paySlipId) endpoint += `/${paySlipId}`;

    return this.http.get<HttpBaseResponse>(endpoint);
  }

  /**
    * @ngdoc function
    * @name newPeriod
    * @param period PayslipPeriodRequest
    * @methodOf ApiPayslipsService
    * @description Crea/Edita un período de recibos de sueldo.
    * @return HttpBaseResponse
    */

  newPeriod(period: PayslipPeriodRequest) {
    return this.http.post<any>(`${this._apiUrl}/${this._controller}/new_period`, period);
  }

  /**
    * @ngdoc function
    * @name newPayslip
    * @param payslip PayslipRequest
    * @methodOf ApiPayslipsService
    * @description Crea un recibo de sueldo.
    * @return HttpBaseResponse
    */

  newPayslip(payslip: PayslipRequest) {
    return this.http.post<any>(`${this._apiUrl}/${this._controller}/new_payslip`, payslip);
  }

  /**
    * @ngdoc function
    * @name deletePeriod
    * @param periodId number
    * @methodOf ApiPayslipsService
    * @description Elimina un periodo de recibos de sueldo.
    * @return HttpBaseResponse
    */

  deletePeriod(periodId: number) {
    return this.http.post<any>(`${this._apiUrl}/${this._controller}/delete_period`, { id: periodId });
  }

  /**
    * @ngdoc function
    * @name deletePayslip
    * @param payslipId number
    * @methodOf ApiPayslipsService
    * @description Eliminar un recibo de sueldo.
    * @return HttpBaseResponse
    */

  deletePayslip(payslipId: number) {
    return this.http.post<any>(`${this._apiUrl}/${this._controller}/delete_payslip`, { id: payslipId });
  }

}
