import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomTableColumnType } from 'src/app/components/custom-table/enums/custom-table-column-type.enum';
import { CustomTableColumn } from 'src/app/components/custom-table/interfaces/custom-table-column';
import { CustomTableGenericButton } from 'src/app/components/custom-table/interfaces/custom-table-generic-button';
import { CustomTableStructure } from 'src/app/components/custom-table/interfaces/custom-table-structure';
import { PaymentStatusEnum } from 'src/app/enums/payment-status-enum';
import { Course } from 'src/app/models/course';
import { CoursesStudentsReport } from 'src/app/models/courses-students-report';
import { SemaphoreColumn } from 'src/app/models/semaphore-column';
import { StudentCourse } from 'src/app/models/student-course';
import { StudentCoursetListItem } from 'src/app/models/student-course-list-item';
import { ApiCoursesService } from 'src/app/services/api-courses.service';
import { ApiReportsService } from 'src/app/services/api-reports.service';
import { ApiStudentsService } from 'src/app/services/api-students.service';
import { IdentityService } from 'src/app/services/identity.service';
import { UserInterfaceService } from 'src/app/services/user-interface.service';

@Component({
  selector: 'app-courses-report',
  templateUrl: './courses-report.component.html',
  styleUrls: ['./courses-report.component.scss']
})
export class CoursesReportComponent implements OnInit {

  dataLoadedSC: boolean = false;

  courses: Course[] = [];
  itemList: CoursesStudentsReport[] = [];
  itemListAux: CoursesStudentsReport[] = [];

  detailsBtn: CustomTableGenericButton = { icon: 'info', text: 'Detalles', color: 'primary' };

  columnsSC: CustomTableColumn[] = [
    { name: 'id', text: 'N° Insc.', type: CustomTableColumnType.TEXT},
    { name: 'id_person', text: 'Legajo', type: CustomTableColumnType.TEXT},
    { name: 'student_name', text: 'Usuario', type: CustomTableColumnType.TEXT },
    { name: 'identity_number', text: 'DNI', type: CustomTableColumnType.TEXT},
    { name: 'course_name', text: 'Curso', type: CustomTableColumnType.TEXT },
    { name: 'enrollment_status_name', text: 'Estado inscripción', type: CustomTableColumnType.TEXT },
    { name: 'enrollment_date', text: 'Fecha inscripción', type: CustomTableColumnType.DATE }];
  // { name: 'last_payment_fee', text: 'Concepto Últ. Pago', type: CustomTableColumnType.TEXT },
  // { name: 'last_payment_date', text: 'Fecha Últ. pago', type: CustomTableColumnType.DATE },
  // { name: 'payment_status_semaphore', text: 'Estado Últ. Pago', type: CustomTableColumnType.SEMAPHORE }];

  tableSC: CustomTableStructure;

  constructor(private APIStudents: ApiStudentsService,
    private identity: IdentityService,
    private changeRef: ChangeDetectorRef,
    private router: Router,
    private APICourses: ApiCoursesService,
    private ui: UserInterfaceService,
    private APIReports: ApiReportsService) { }

  ngOnInit(): void {
    this.getStudentCourses();
  }

  getStudentCourses() {
    this.ui.spin$.next(true);
    this.APICourses.getStudentsReport().subscribe(response => {
      this.itemList = response.data;

      this.APICourses.getAll().subscribe(courses => {
        this.courses = courses.data;
        this.ui.spin$.next(false);
      });

      // this.studentCourses.forEach(sc => {
      //   this.itemList.push({
      //     id: sc.id,
      //     name: sc.student_name,
      //     enrollment_date: sc.enrollment_date,
      //     last_payment_date: sc.last_payment_date,
      //     last_payment_fee: this.getPaymentConcept(sc),
      //     payment_status_semaphore: this.getPaymentStatusSemaphore(sc),
      //     person_name: sc.person_name
      //   });
      // });

      this.itemListAux = this.itemList;

      this.tableSC = { columns: this.columnsSC, data: this.itemList };
      this.changeRef.detectChanges();
      this.dataLoadedSC = true;
    });
  }

  getPaymentConcept(sc: StudentCourse) {
    if (sc.last_payment_fee == 0) {
      return "Matrícula";
    }
    else {
      return "Cuota" + sc.last_payment_fee.toString() + " de " + sc.fees.toString();
    }
  }

  getPaymentStatusSemaphore(sc: StudentCourse) {
    let color: string = '#fd7a7e';

    switch (sc.payment_status_fee.toString()) {
      case PaymentStatusEnum.COMPLETE.toString():
        color = '#8ac926';
        break;
      case PaymentStatusEnum.INCOMPLETE.toString():
        color = '#f6bd40';
        break;
    }

    let semaphore: SemaphoreColumn = {
      value: color,
      label: sc.payment_status_fee_name
    };

    return semaphore;
  }

  onCourseChange(courseId: number) {

    if (courseId == 0) {
      this.itemList = this.itemListAux;
    }
    else {
      this.itemList = this.itemListAux.filter(il => il.id_course.toString() == courseId.toString());
    }
    this.tableSC = { columns: this.columnsSC, data: this.itemList };
  }

  goToDetails(item: any) {
    this.router.navigate(['/student-course-details/' + item.id_course + '/' + item.id + '/' + item.id_person]);
  }

  exportExcelStudentCourses() {
    this.ui.spin$.next(true);
    this.APIReports.exportExcelStudentsCourses().subscribe(response => {
      const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      // const url = window.URL.createObjectURL(blob);
      // window.open(url);
      let link = document.createElement("a");
      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "Alumnos.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.ui.spin$.next(false);
    });
  }

  exportPaymentsStatus() {
    this.ui.spin$.next(true);
    this.APIReports.exportPaymentsStatus().subscribe(response => {
      const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      // const url = window.URL.createObjectURL(blob);
      // window.open(url);
      let link = document.createElement("a");
      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "EstadosDePago.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.ui.spin$.next(false);
    });
  }

}
