<div class="container container-courselist">
  <div style="max-height: 10%; flex: 1 1 100%; box-sizing: border-box; margin-bottom: 20px;">
    <section-header title="Gestión de cargos" [showNewButton]='true' (newButtonPressed)="new()"></section-header>
  </div>

  <div style="flex: 1 1 1e-9px; box-sizing: border-box;">
    <mat-card>
      <mat-card-content>
        <custom-table *ngIf="dataLoadedALL" [dataSource]="tableAllStudents" [paginatorPageSize]="20" [filterable]="true" [showActions]="true"
          [showGenericButton2]="true" [genericButton2]="detailsBtn" genericButton2Title="Detalles" (genericButton2Pressed)="goToDetails($event)"
        ></custom-table>
      </mat-card-content>
    </mat-card>
  </div>
</div>
