import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { CustomTableColumnType } from 'src/app/components/custom-table/enums/custom-table-column-type.enum';
import { CustomTableColumn } from 'src/app/components/custom-table/interfaces/custom-table-column';
import { CustomTableGenericButton } from 'src/app/components/custom-table/interfaces/custom-table-generic-button';
import { CustomTableStructure } from 'src/app/components/custom-table/interfaces/custom-table-structure';
import { ImageDialogComponent } from 'src/app/components/image-dialog/image-dialog.component';
import { UserProfilesEnum } from 'src/app/enums/user-profiles-enum';
import { Payslip } from 'src/app/models/payslip';
import { PayslipPeriod } from 'src/app/models/payslip-period';
import { User } from 'src/app/models/user';
import { ApiPayslipsService } from 'src/app/services/api-payslips.service';
import { ApiUsersService } from 'src/app/services/api-users.service';
import { IdentityService } from 'src/app/services/identity.service';
import { UserInterfaceService } from 'src/app/services/user-interface.service';

@Component({
  selector: 'app-payslips-list',
  templateUrl: './payslips-list.component.html',
  styleUrls: ['./payslips-list.component.scss']
})
export class PayslipsListComponent implements OnInit {

  payslips: Payslip[] = [];
  periods: PayslipPeriod[] = [];
  users: User[] = [];

  // FILTROS
  fPeriod: number = 0;
  fUser: number = 0;

  dataLoaded: boolean = false;

  userId?: number;
  profileId?: number;

  columnsTable: CustomTableColumn[] = [
    { name: 'period_name', text: 'Período', type: CustomTableColumnType.TEXT },
    { name: 'name', text: '', type: CustomTableColumnType.TEXT }];

  payslipsTable: CustomTableStructure;

  viewReceiptBtn: CustomTableGenericButton = { icon: 'file_open', text: '', color: 'primary', title: "Ver Recibo de sueldo" };

  userProfiles: typeof UserProfilesEnum = UserProfilesEnum;

  constructor(private APIPayslips: ApiPayslipsService,
    private ui: UserInterfaceService,
    private router: Router,
    private identity: IdentityService,
    private payslipDialog: MatDialog,
    private APIUsers: ApiUsersService,
    private alertDialog: MatDialog) {


    this.profileId = this.identity.getDecodedToken().id_profile;
    if (this.userIsAdmin()) {
      this.columnsTable.push({ name: 'user_name', text: 'Usuario', type: CustomTableColumnType.TEXT });
    }
    else {
      this.userId = this.identity.getDecodedToken().id_user;
    }

  }

  ngOnInit(): void {
    this.ui.spin$.next(true);

    this.getPayslipsPeriods().then(() => {
      this.getPayslips().then(() => {
        if (this.userIsAdmin()) {
          this.getUsers().then(() => {
            this.ui.spin$.next(false);
          });
        }
        else {
          this.ui.spin$.next(false);
        }
      });
    });
  }

  getPayslips(): Promise<void> {
    return new Promise(resolve => {

      this.APIPayslips.getPayslips(this.userId).subscribe(response => {
        this.payslips = response.data;

        this.payslipsTable = {
          columns: this.columnsTable, data: this.payslips
        }

        this.dataLoaded = true;
        resolve();
      });
    });
  }

  getPayslipsPeriods(): Promise<void> {
    return new Promise(resolve => {
      this.APIPayslips.getPayslipsPeriods().subscribe(periods => {
        this.periods = periods.data;
        resolve();
      });
    });
  }

  getUsers(): Promise<void> {
    return new Promise(resolve => {
      this.APIUsers.getUsersPayslips().subscribe(response => {
        this.users = response.data;
        resolve();
      });
    });
  }

  showFile(payslip: Payslip) {
    if (payslip.file!.split('.').pop() != 'pdf') {
      this.payslipDialog.open(ImageDialogComponent, {
        data: {
          title: payslip.period_name! + ": " + payslip.name,
          url: payslip.file,
          printable: true
        }
      });
    }
    else {
      window.open(payslip.file, "_blank");
    }
  }

  filter() {
    let listToFilter = this.payslips;

    if (this.fPeriod != 0) {
      listToFilter = listToFilter.filter(ps => ps.id_period.toString() == this.fPeriod.toString());
    }

    if (this.fUser != 0) {
      listToFilter = listToFilter.filter(ps => ps.id_user.toString() == this.fUser.toString());
    }

    this.payslipsTable = { columns: this.columnsTable, data: listToFilter };
  }

  onPeriodChange(periodId: number) {
    this.fPeriod = periodId;
    this.filter();
  }

  onUserChange(userId: number) {
    this.fUser = userId;
    this.filter();
  }

  new() {
    this.router.navigate(['payslips-form'])
  }

  userIsAdmin() {
    return (this.profileId == this.userProfiles.ADMIN);
  }

  delete(payslipId: number) {
    this.ui.spin$.next(true);
    this.APIPayslips.deletePayslip(payslipId).subscribe(() => {
      this.getPayslips().then(() => {
        this.ui.spin$.next(false);
      });
    });
  }

}
