import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  receiptFileValidation(type: string) {
    let valid: boolean = false;

    switch (type) {
      case "image/jpeg":
      case "image/png":
      case "application/pdf":
        valid = true;
        break;
    }

    return valid;
  }

  payslipFileValidation(type: string) {
    let valid: boolean = false;

    switch (type) {
      case "image/jpeg":
      case "image/png":
      case "application/pdf":
        valid = true;
        break;
    }

    return valid;
  }

  userFileValidation(type: string) {
    let valid: boolean = false;

    switch (type) {
      case "image/jpeg":
      case "image/png":
        valid = true;
        break;
    }

    return valid;
  }
}
