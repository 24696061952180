import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomTableColumnType } from 'src/app/components/custom-table/enums/custom-table-column-type.enum';
import { CustomTableColumn } from 'src/app/components/custom-table/interfaces/custom-table-column';
import { CustomTableStructure } from 'src/app/components/custom-table/interfaces/custom-table-structure';
import { Course } from 'src/app/models/course';
import { CourseListItem } from 'src/app/models/course-list-item';
import { ApiCoursesService } from 'src/app/services/api-courses.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-courses-list',
  templateUrl: './courses-list.component.html',
  styleUrls: ['./courses-list.component.scss']
})
export class CoursesListComponent implements OnInit {

  courses: CourseListItem[] = [];

  dataLoaded: boolean = false;

  columns: CustomTableColumn[] = [
    { name: 'id', text: 'N° Curso', type: CustomTableColumnType.TEXT},
    { name: 'name', text: 'Nombre', type: CustomTableColumnType.TEXT },
    { name: 'vacancies_enrollments', text: 'Cubiertas / Vacantes', type: CustomTableColumnType.TEXT },
    { name: 'dates', text: 'Fechas', type: CustomTableColumnType.TEXT },
    { name: 'enrollment_price', text: 'Precio Matrícula', type: CustomTableColumnType.NUMBER},
    { name: 'fees', text: 'Cuotas', type: CustomTableColumnType.NUMBER },
    { name: 'fee_price', text: 'Precio Cuota', type: CustomTableColumnType.NUMBER }];

  customTableStructure: CustomTableStructure;
  constructor(private utils: UtilsService,
    private APICourses: ApiCoursesService,
    private router: Router) {
  }

  ngOnInit(): void {
    this.getList();
  }

  getList() {
    this.APICourses.getList().subscribe(response => {
      this.courses = response.data;

      this.customTableStructure = { columns: this.columns, data: this.courses };
      this.dataLoaded = true;
    });
  }

  new() {
    this.router.navigate(['courses-form']);
  }

  goToEdit(id: number) {
    this.router.navigate(['courses-form', id]);
  }

  delete(id: number) {
    this.APICourses.delete(id).subscribe(response => {
      this.getList();
    })
  }

}
