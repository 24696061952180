<h1>
    TEST VIEW

</h1>
<div>
    <p>
        
        Aca pongo el lottie: {{pepito}}

    </p>
    <test-component />    
    <lottie-component />
</div>
