<div class="container container-courselist">
  <div style="max-height: 10%; flex: 1 1 100%; box-sizing: border-box; margin-bottom: 20px;">
    <section-header title="Cursos" [showNewButton]="false"></section-header>
  </div>

  <div style="flex: 1 1 1e-9px; box-sizing: border-box;">
    <mat-card *ngIf="!availableCourses" class="closed-enrollments-alert">
      <mat-icon>warning</mat-icon> Las inscripciones a los cursos se encuentran cerradas.
    </mat-card>
    <mat-tab-group animationDuration="0ms">
      <mat-tab label="Mis cursos">
        <mat-card>
          <mat-card-content>
            <custom-table *ngIf="dataLoadedSC" [dataSource]="tableSC" [showActions]="true" [showDetailsButton]="true"
              (detailsButtonPressed)="goToCourseDetails($event)"></custom-table>
          </mat-card-content>
        </mat-card>
      </mat-tab>
      
      <mat-tab label="Todos los cursos">
        <mat-card>
          <mat-card-content>
            <custom-table *ngIf="dataLoadedAC" [dataSource]="tableAC" [showActions]="true"
            [showGenericButton1]="true"
            [genericButton1]="enrollBtn"
            (genericButton1Pressed)="showMoreInfo($event)"></custom-table>
          </mat-card-content>
        </mat-card>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
