import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PayslipPeriodRequest } from 'src/app/models/payslip-period-request';
import { ApiPayslipsService } from 'src/app/services/api-payslips.service';
import { UserInterfaceService } from 'src/app/services/user-interface.service';

@Component({
  selector: 'app-payslips-periods-form',
  templateUrl: './payslips-periods-form.component.html',
  styleUrls: ['./payslips-periods-form.component.scss']
})
export class PayslipsPeriodsFormComponent implements OnInit {

  periodForm: UntypedFormGroup;

  months = [
    { id: "1", value: "Enero" },
    { id: "2", value: "Febrero" },
    { id: "3", value: "Marzo" },
    { id: "4", value: "Abril" },
    { id: "5", value: "Mayo" },
    { id: "6", value: "Junio" },
    { id: "7", value: "Julio" },
    { id: "8", value: "Agosto" },
    { id: "9", value: "Septiembre" },
    { id: "10", value: "Octubre" },
    { id: "11", value: "Noviembre" },
    { id: "12", value: "Diciembre" },
  ]

  constructor(private fb: UntypedFormBuilder,
    private ui: UserInterfaceService,
    private APIPayslips: ApiPayslipsService,
    private router: Router,
    private route: ActivatedRoute) {
    this.periodForm = this.fb.group({
      id: [0, Validators.required],
      name: [null, Validators.required],
      year: [(new Date()).getFullYear(), Validators.required],
      month: [((new Date()).getMonth() + 1).toString(), Validators.required]
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params['periodId']) {
        this.periodForm.get('id')?.setValue(params['periodId']);
        this.getPeriod();
      }
      else {
        this.setName();
      }
    });

  }

  getPeriod() {
    this.ui.spin$.next(true);
    this.APIPayslips.getPayslipsPeriods(this.periodForm.get('id')!.value).subscribe(period => {
      this.periodForm.patchValue(period.data);
      this.ui.spin$.next(false);
    });
  }

  setName() {
    let monthName = this.months.find(m => m.id == this.periodForm.get('month')!.value)?.value;
    let year = this.periodForm.get('year')!.value;

    this.periodForm.get('name')?.setValue(monthName + ' ' + year);
  }

  save() {
    if (this.periodForm.valid) {
      this.ui.spin$.next(true);

      let period: PayslipPeriodRequest = this.periodForm.getRawValue();

      this.APIPayslips.newPeriod(period).subscribe((response) => {
        this.ui.spin$.next(false);
        this.router.navigate(["payslips-periods"]);
      });
    }
    else {
      Object.keys(this.periodForm.controls).forEach(field => {
        const control = this.periodForm.get(field);
        control?.markAsTouched({ onlySelf: true });
      });
    }
  }

}
