import { Component, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { LottieComponent, AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'test-component',
  templateUrl: "./test.html",
  standalone: true,
  styleUrls: ['./test.scss'],
  imports: [LottieComponent],

})
export class TestComponent {
  testText: string = 'Hello World';
  optionsLogo1: AnimationOptions = {
    path: '/assets/lotties/fab_logo_animated.json',
  };
  optionsLogo2: AnimationOptions = {
    path: '/assets/lotties/fab_logo_animated(2).json',
  };

  animationCreated(animationItem: AnimationItem): void {
  }
  anAnimationItem: AnimationItem;

}
