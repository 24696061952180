import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PaymentStatusEnum } from 'src/app/enums/payment-status-enum';
import { UserProfilesEnum } from 'src/app/enums/user-profiles-enum';
import { CoursePaymentsStatus } from 'src/app/models/course-payments-status';
import { FeesPaymentRequest } from 'src/app/models/payment-requests';
import { ApiPaymentsService } from 'src/app/services/api-payments.service';
import { IdentityService } from 'src/app/services/identity.service';

@Component({
  selector: 'payment-fees',
  templateUrl: './payment-fees.component.html',
  styleUrls: ['./payment-fees.component.scss']
})
export class PaymentFeesComponent implements OnInit, OnChanges {

  @Input() created: boolean = false;
  @Input() courseId: number;
  @Input() enrollmentPrice: number;
  @Input() feePrice: number;
  @Input() personId: number;
  @Input() form: UntypedFormGroup;
  @Input() selectedFees: string[] = [];

  profileId: number;

  pendingFees: CoursePaymentsStatus[] = [];

  fees: FeesPaymentRequest[] = [];

  statusValues: typeof PaymentStatusEnum = PaymentStatusEnum;
  profilesValues: typeof UserProfilesEnum = UserProfilesEnum;

  constructor(private APIPayments: ApiPaymentsService,
    private identity: IdentityService) { }

  ngOnInit(): void {
    this.profileId = this.identity.getDecodedToken().id_profile;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getFees();
    this.onFeesChange(this.selectedFees);
  }

  getFees() {
    this.APIPayments.getPendingFees(this.courseId, this.personId, this.created).subscribe(response => {
      this.pendingFees = response.data;
    });
  }

  getFeesFieldLabels(pf: CoursePaymentsStatus) {
    let label: string = (pf.fee_number == 0) ? 'Matrícula' : 'Cuota ' + pf.fee_number;

    if (pf.payment_status == PaymentStatusEnum.COMPLETE) {
      label += ' (ABONADA)';
    }
    else if (pf.payment_status == PaymentStatusEnum.INCOMPLETE) {
      label += ' (Restan abonar: $' + pf.pending_amount + ')';
    }
    else if (pf.payment_status == PaymentStatusEnum.PENDING) {
      label += ' ($' + pf.fee_price + ')';
    }

    return label;
  }

  getFeesLabel(pf: CoursePaymentsStatus) {
    let label: string

    if (pf.fee_number == 0) {
      label = 'Matrícula';

      if (pf.payment_status == PaymentStatusEnum.COMPLETE) {
        label += ' (ABONADA)';
      }
    } else {
      label = `Cuota ($${pf.fee_price})`;
    }

    return label;
  }

  isFeeDisabled(pf: CoursePaymentsStatus) {
    return (pf.payment_status == PaymentStatusEnum.COMPLETE) ? true : false;
  }

  getFeeMaxPrice(pf: FeesPaymentRequest) {
    const feePrice = this.pendingFees.find(f => f.fee_number == pf.number);
    if (!feePrice) return ""
    else if (feePrice.payment_status == PaymentStatusEnum.PENDING) return ' (Máx: '+ feePrice.fee_price +')'
    return ' (Máx: '+ feePrice.pending_amount +')'
  }

  onFeesChange(values: any) {
    this.selectedFees = values;
    let feesAux: FeesPaymentRequest[] = this.fees;
    this.fees = [];

    values.forEach((v: any) => {
      let f = feesAux.find(f => f.number == v);

      (f) ?
        this.fees.push({ number: f.number, amount: f.amount }) :
        this.fees.push({ number: v, amount: 0 });
    });

    this.form.get('fees')?.setValue(this.fees);
  }

  onAmountChange(fee_number: number, event: any) {
    this.fees.find(f => f.number == fee_number)!.amount = event.target.value;
    this.form.get('fees')?.setValue(this.fees);
  }

}
