import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import jwtDecode, { JwtPayload } from "jwt-decode";
import { BehaviorSubject } from 'rxjs';
import { LoginRequest } from '../models/login-request';
import { ApiUsersService } from './api-users.service';
import { UserProfilesEnum } from '../enums/user-profiles-enum';
import { UserLogged } from '../models/user-logged';

// TESORERIA
const genericToken: string = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZF91c2VyIjoiMSIsImlkX3BlcnNvbiI6bnVsbCwiaWRfcHJvZmlsZSI6IjEiLCJpZF9zdHVkZW50IjpudWxsfQ.lpeZQoJD9FkNZtRil612fGE5mViMyhKqDo4UdDV7AgY";
// ALUMNO
// const token: string = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZF91c2VyIjoiMyIsImlkX3BlcnNvbiI6IjEiLCJpZF9wcm9maWxlIjoiMyJ9.5NwCGYdJx8PdqxqoESWm0eKeeee-zxEeGZgqmYGnWZU";

@Injectable({
  providedIn: 'root'
})
export class IdentityService {

  private loggedIn = new BehaviorSubject<boolean>(this.tokenExists());
  private usePayslips = new BehaviorSubject<boolean>(this.getUsePayslipsFromToken());
  private useCredential = new BehaviorSubject<boolean>(this.getUseCredentialFromToken());

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  get getUsePayslips() {
    return this.usePayslips.asObservable();
  }

  get getUseCredential() {
    return this.useCredential.asObservable();
  }

  constructor(private APIUsers: ApiUsersService,
    private router: Router) { }

  login(user: LoginRequest) {
    this.APIUsers.validateCredentials(user).subscribe({
      next: (loggedUser: UserLogged) => {
        if (loggedUser.active == "1") {
          this.loggedIn.next(true);
          this.usePayslips.next((loggedUser.use_payslips == '1'));
          this.useCredential.next((loggedUser.use_credential !== null));
          localStorage.setItem("token", loggedUser.token);

          const todayDate = new Date();
          todayDate.setHours(todayDate.getHours() + 2)
          localStorage.setItem("expired", todayDate.toISOString());

          switch (parseInt(loggedUser.id_profile)) {
            case UserProfilesEnum.TREASURY:
              this.router.navigate(['payment-receipts-list']);
              break;

            case UserProfilesEnum.SCHOOL:
              this.router.navigate(['courses-list']);
              break;

            case UserProfilesEnum.SECRETARY:
              this.router.navigate(['courses-list']);
              break;

            case UserProfilesEnum.STUDENT:
              this.router.navigate(['student-courses']);
              break;

            case UserProfilesEnum.ADMIN:
              this.router.navigate(['payslips']);
              break;
          }
        }
        else {
          alert("El usuario no se encuentra activado. Al registrarse le hemos enviado un correo, en caso de no recibirlo puede intentar activar su usuario mediante el botón de OLVIDÉ MI CONTRASEÑA.")
        }
      },
      error: (e) => {
        alert("El email y contraseña no corresponden a un usuario del sistema.");
        
      }
    });
  }

  impersonate(impersonateRequest: any) {
    this.APIUsers.impersonatePerson(impersonateRequest).subscribe({
      next: (loggedUser: UserLogged) => {
        if (loggedUser.active == "1") {
          this.loggedIn.next(true);
          this.usePayslips.next((loggedUser.use_payslips == '1'));
          this.useCredential.next((loggedUser.use_credential !== null));
          localStorage.setItem("token", loggedUser.token);

          const todayDate = new Date();
          todayDate.setHours(todayDate.getHours() + 2)
          localStorage.setItem("expired", todayDate.toISOString());

          switch (parseInt(loggedUser.id_profile)) {
            case UserProfilesEnum.TREASURY:
              this.router.navigate(['payment-receipts-list']);
              break;

            case UserProfilesEnum.SCHOOL:
              this.router.navigate(['courses-list']);
              break;

            case UserProfilesEnum.SECRETARY:
              this.router.navigate(['courses-list']);
              break;

            case UserProfilesEnum.STUDENT:
              this.router.navigate(['student-courses']);
              break;

            case UserProfilesEnum.ADMIN:
              this.router.navigate(['payslips']);
              break;
          }
        }
        else {
          alert("El usuario no se encuentra activado. Al registrarse le hemos enviado un correo, en caso de no recibirlo puede intentar activar su usuario mediante el botón de OLVIDÉ MI CONTRASEÑA.")
        }
      },
      error: (e) => {
        alert("El email y contraseña no corresponden a un usuario del sistema.");
        
      }
    });
  }

  logout(redirect:boolean= true) {
    this.loggedIn.next(false);
    localStorage.clear();
    sessionStorage.clear();

    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    }

    // window.history.pushState('', '', "/login");
    redirect && window.location.replace(window.location.href);
  }

  getDecodedToken() {
    let token = localStorage.getItem('token');
    const decoded = jwtDecode<JwtPayload>(token!);
    return <any>decoded;
  }

  getToken() {
    let token = genericToken;
    if (this.tokenExists()) {
      token = localStorage.getItem('token')!;
    }
    return token;
  }

  tokenExists(): boolean {
    return !!localStorage.getItem('token');
  }

  getUsePayslipsFromToken(): boolean {
    if (!!localStorage.getItem('token')) {
      return (this.getDecodedToken().use_payslips == '1');
    }
    else {
      return false;
    }
  }

  getUseCredentialFromToken(): boolean {
    if (!!localStorage.getItem('token')) {
      return (this.getDecodedToken().use_credential !== null);
    }
    else {
      return false;
    }
  }
}
