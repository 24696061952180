<div class="container container-courselist">
  <div style="margin-bottom: 20px;">
    <section-header title="Recibos de Sueldo" [showNewButton]="userIsAdmin()" (newButtonPressed)="new()">
    </section-header>
  </div>

  <div style="flex: 1 1 1e-9px; box-sizing: border-box;">
    <mat-card>
      <div style="display: flex; flex-direction: row; box-sizing: border-box; flex: 1 1 100%; max-height: 10%;">
        <mat-form-field appearance="outline" style="margin-right: 20px;">
          <mat-label>Filtrar por Período</mat-label>
          <mat-select (valueChange)="onPeriodChange($event)">
            <mat-option value="0">-- Todos --</mat-option>
            <mat-option *ngFor="let p of periods" [value]="p.id">{{p.name}}</mat-option>
          </mat-select>
        </mat-form-field>
  
        <mat-form-field appearance="outline" *ngIf="userIsAdmin()">
          <mat-label>Filtrar por Usuario</mat-label>
          <mat-select (valueChange)="onUserChange($event)">
            <mat-option value="0">-- Todos --</mat-option>
            <mat-option *ngFor="let u of users" [value]="u.id">{{u.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <mat-card-content>
        <custom-table *ngIf="dataLoaded" [dataSource]="payslipsTable" [paginatorPageSize]="20" [filterable]="false"
          [showActions]="true" [showEditButton]="false" [showDeleteButton]="userIsAdmin()" (deleteButtonPressed)="delete($event)" [showGenericButton2]="true"
          (genericButton2Pressed)="showFile($event)" [genericButton2]="viewReceiptBtn" genericButton2Title="Ver recibo de sueldo">
        </custom-table>
      </mat-card-content>
    </mat-card>

  </div>
</div>
