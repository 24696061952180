import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DocumentTypesEnum } from 'src/app/enums/document-types-enum';
import { PayslipPeriod } from 'src/app/models/payslip-period';
import { PayslipRequest } from 'src/app/models/payslip-request';
import { User } from 'src/app/models/user';
import { ApiPayslipsService } from 'src/app/services/api-payslips.service';
import { ApiUsersService } from 'src/app/services/api-users.service';
import { UserInterfaceService } from 'src/app/services/user-interface.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-payslips-form',
  templateUrl: './payslips-form.component.html',
  styleUrls: ['./payslips-form.component.scss']
})
export class PayslipsFormComponent implements OnInit {

  periods: PayslipPeriod[] = [];
  users: User[] = [];

  payslipForm: UntypedFormGroup;

  payslipFileValid: boolean = true;

  constructor(private fb: UntypedFormBuilder,
    private utils: UtilsService,
    private ui: UserInterfaceService,
    private APIPayslips: ApiPayslipsService,
    private APIUsers: ApiUsersService,
    private router: Router) {
    this.payslipForm = this.fb.group({
      id_period: [null, Validators.required],
      id_user: [null, Validators.required],
      id_doc_type: [DocumentTypesEnum.PAYSLIPS, Validators.required],
      name: [null, Validators.required],
      file_base64: [null, Validators.required]
    });
  }

  ngOnInit(): void {
    this.ui.spin$.next(true);
    this.getPeriods().then(() => {
      this.getUsers().then(() => {
        this.ui.spin$.next(false);
      });
    });
  }

  getPeriods(): Promise<void> {
    return new Promise(resolve => {
      this.APIPayslips.getPayslipsPeriods().subscribe(response => {
        this.periods = response.data;
        resolve();
      });
    });
  }

  getUsers(): Promise<void> {
    return new Promise(resolve => {
      this.APIUsers.getUsersPayslips().subscribe(response => {
        this.users = response.data;
        resolve();
      });
    });
  }

  onFileChanged(event: any) {
    this.payslipFileValid = this.utils.payslipFileValidation(event.type);
    this.payslipForm.get('file_base64')?.setValue(event.base64);
  }

  save() {
    if (this.payslipForm.valid && this.payslipFileValid) {
      this.ui.spin$.next(true);

      let payslip: PayslipRequest = this.payslipForm.getRawValue();

      this.APIPayslips.newPayslip(payslip).subscribe((response) => {
        this.ui.spin$.next(false);
        this.router.navigate(["payslips"]);
      });
    }
    else {
      Object.keys(this.payslipForm.controls).forEach(field => {
        const control = this.payslipForm.get(field);
        control?.markAsTouched({ onlySelf: true });
      });
    }
  }

}
