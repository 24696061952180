import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { EnrollmentStatusEnum } from 'src/app/enums/enrollment-status-enum';
import { PaymentStatusEnum } from 'src/app/enums/payment-status-enum';
import { SemaphoreColumn } from 'src/app/models/semaphore-column';
import { StudentCourse } from 'src/app/models/student-course';
import { StudentCoursetListItem } from 'src/app/models/student-course-list-item';
import { StudentListItem } from 'src/app/models/student-list-item';
import { ApiStudentsService } from 'src/app/services/api-students.service';
import { CustomTableColumnType } from '../custom-table/enums/custom-table-column-type.enum';
import { CustomTableColumn } from '../custom-table/interfaces/custom-table-column';
import { CustomTableGenericButton } from '../custom-table/interfaces/custom-table-generic-button';
import { CustomTableStructure } from '../custom-table/interfaces/custom-table-structure';
import { EnrollmentDialogComponent } from '../enrollment-dialog/enrollment-dialog.component';

@Component({
  selector: 'students-by-status-table',
  templateUrl: './students-by-status-table.component.html',
  styleUrls: ['./students-by-status-table.component.scss']
})
export class StudentsByStatusTableComponent implements OnInit {

  @Input() enrollmentStatus: number;

  studentList: StudentListItem[] = [];

  dataLoaded: boolean = false;

  approveEnrollBtn: CustomTableGenericButton = { icon: 'check', text: 'Validar Inscripción', color: 'primary', title: "Validarla" };
  enrollBtnIcon: CustomTableGenericButton = { icon: 'assignment_turned_in', text: '', color: 'primary' };

  columnsPE: CustomTableColumn[] = [
    { name: 'id', text: 'N° Inscripción', type: CustomTableColumnType.TEXT },
    { name: 'id_person', text: 'Legajo', type: CustomTableColumnType.TEXT},
    { name: 'person_name', text: 'Nombre', type: CustomTableColumnType.TEXT },
    { name: 'identity_number', text: 'DNI', type: CustomTableColumnType.TEXT },
    { name: 'course_name', text: 'Curso', type: CustomTableColumnType.TEXT }];

  tableAllStudents: CustomTableStructure;
  tablePendingEnrollments: CustomTableStructure;

  constructor(private APIStudents: ApiStudentsService,
    private changeRef: ChangeDetectorRef,
    private enrollmentDialog: MatDialog) { }

  ngOnInit(): void {
    this.getStudents(this.enrollmentStatus);
  }

  getStudents(status: number) {
    this.APIStudents.getStudentsByStatus(status).subscribe(response => {
      this.studentList = response.data;
      this.tablePendingEnrollments = { columns: this.columnsPE, data: this.studentList };
      this.changeRef.detectChanges();
      this.dataLoaded = true;
    });
  }

  getPaymentStatusSemaphore(sc: StudentCourse) {
    let color: string = '#fd7a7e';

    switch (sc.payment_status_fee.toString()) {
      case PaymentStatusEnum.COMPLETE.toString():
        color = '#8ac926';
        break;
      case PaymentStatusEnum.INCOMPLETE.toString():
        color = '#f6bd40';
        break;
    }

    let semaphore: SemaphoreColumn = {
      value: color,
      label: sc.payment_status_fee_name
    };

    return semaphore;
  }

  showEnrollDialog(studentId: number) {
    let studentSelected = this.studentList.find(p => p.id == studentId);

    const dialogRef = this.enrollmentDialog.open(EnrollmentDialogComponent, {
      width: "90%",
      maxWidth: "800px",
      data: {
        title: "Validar Inscripción",
        studentId: studentId,
        courseId: studentSelected?.id_course,
        personId: studentSelected?.id_person,
        studentName: studentSelected?.person_name,
        enrollmentStatusId: studentSelected?.id_enrollment_status
      }
    });

    dialogRef.afterClosed().subscribe(finished => {
      this.getStudents(this.enrollmentStatus);
    });
  }

}
