<div class="container"
  style="display: flex; flex-direction: row; box-sizing: border-box; place-content: stretch space-around; align-items: stretch; width: 100%; height: 100%;">
  <div class="max-size-flex">
    <h1>Recibos de sueldo - Nuevo período</h1>
    <mat-card>
      <form [formGroup]="periodForm" (ngSubmit)="save()">
        <mat-card-content>
          <div style="display: flex; flex-direction: row; box-sizing: border-box;">
            <mat-form-field style="max-width: 25%; flex: 1 1 100%; box-sizing: border-box; margin-right: 20px;"
              appearance="outline">
              <mat-label>Mes</mat-label>
              <mat-select formControlName="month" (ngModelChange)="setName()">
                <mat-option *ngFor="let m of months" [value]="m.id">{{ m.value }}</mat-option>
              </mat-select>
              <mat-error *ngIf="periodForm.get('month')?.hasError('required')">
                Este campo es <strong>obligatorio.</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field style="max-width: 25%; flex: 1 1 100%; box-sizing: border-box; margin-right: 20px;" appearance="outline"
              class="full-width">
              <mat-label>Año</mat-label>
              <input matInput type="number" formControlName="year" step="1" placeholder="Ingrese año del período" (change)="setName()">

              <mat-error *ngIf="periodForm.get('year')?.hasError('required')">
                Este campo es <strong>obligatorio.</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field style="max-width: 50%; flex: 1 1 100%; box-sizing: border-box;" appearance="outline" class="full-width">
              <mat-label>Nombre</mat-label>
              <input matInput formControlName="name" placeholder="Ingrese nombre del período">

              <mat-error *ngIf="periodForm.get('name')?.hasError('required')">
                Este campo es <strong>obligatorio.</strong>
              </mat-error>
            </mat-form-field>
          </div>

          <div
            style="display: flex; flex-direction: row; box-sizing: border-box; place-content: stretch flex-end; align-items: stretch;">
            <button mat-flat-button type="submit" color="primary">
              <mat-icon>save</mat-icon>
              Guardar
            </button>

          </div>
        </mat-card-content>
      </form>
    </mat-card>
  </div>
</div>
