import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'section-header',
  templateUrl: './section-header.component.html',
  styleUrls: ['./section-header.component.scss']
})
export class SectionHeaderComponent implements OnInit {

  @Input() title: string;
  @Input() showNewButton: boolean = false;

  @Output() newButtonPressed: EventEmitter<void> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onClickNewButton() {
    this.newButtonPressed.emit();
  }

}
