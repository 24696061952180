<div class="container container-courselist">
  <div style="max-height: 10%; flex: 1 1 100%; box-sizing: border-box; margin-bottom: 20px;">
    <section-header title="Alumnos" [showNewButton]="(profileId == userProfilesValues.SCHOOL) || (profileId == userProfilesValues.SECRETARY)" (newButtonPressed)="new()"></section-header>
  </div>

  <div style="flex: 1 1 1e-9px; box-sizing: border-box;">
    <mat-tab-group animationDuration="0ms">
      <mat-tab label="Todos los alumnos">
        <mat-card>
          <mat-card-content>
            <custom-table *ngIf="dataLoadedALL" [dataSource]="tableAllStudents" [paginatorPageSize]="20" [filterable]="true" [showActions]="true"
              [showEditButton]="(profileId == userProfilesValues.SCHOOL) || (profileId == userProfilesValues.SECRETARY)" [showDeleteButton]="profileId == userProfilesValues.SCHOOL" (editButtonPressed)="goToEdit($event)" (deleteButtonPressed)="deleteStudent($event)"
              [showGenericButton1]="profileId == userProfilesValues.SCHOOL" [genericButton1]="enrollBtnIcon" genericButton1Title="Asignar inscripción" (genericButton1Pressed)="showEnrollDialog($event)"
              [showGenericButton2]="profileId == userProfilesValues.TREASURY" [genericButton2]="addPaymentBtn" genericButton2Title="Agregar Comprobante" (genericButton2Pressed)="showCourseListDialog($event)"
              [showGenericButton3]="profileId == userProfilesValues.SECRETARY" [genericButton3]="credentialBtn" genericButton3Title="Credencial" (genericButton3Pressed)="showCredentialDialog($event)"
              ></custom-table>
          </mat-card-content>
        </mat-card>
      </mat-tab>
      <mat-tab label="Inscripciones pendientes" *ngIf="profileId == userProfilesValues.SCHOOL">
        <mat-card>
          <mat-card-content>
            <students-by-status-table [enrollmentStatus]="enrollmentStatus.PENDING"></students-by-status-table>
          </mat-card-content>
        </mat-card>
      </mat-tab>
    </mat-tab-group>

  </div>
</div>
