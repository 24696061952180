<mat-toolbar color="primary" *ngIf="!isScanner && (isLoggedIn$ | async) && showNav">
  <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
  </button>
  <img src="assets/logoFooterFAB.webp" alt="FAB" style="height: 35px;padding-left: 10px;">
  <span class="spacer"></span>
  <label style="overflow: hidden;">{{loggedUser}}</label>
  <button title="Cerrar Sesión"  type="button" aria-label="Toggle sidenav" mat-icon-button (click)="logout()">
    <mat-icon aria-label="Side nav toggle icon">logout</mat-icon>
  </button>
</mat-toolbar>

<mat-sidenav-container class="sidenav-container" >
  <mat-sidenav #drawer class="sidenav" opened
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="((!(isLoggedIn$ | async) || (isHandset$ | async)) === false) && !isScanner && showNav">
    <mat-nav-list style='background-color: white;'>
      <a mat-list-item *ngIf="showMenuByProfile([userProfiles.SCHOOL])" href="courses-list"><mat-icon>book</mat-icon> Cursos</a>
      <a mat-list-item *ngIf="showMenuByProfile([userProfiles.TREASURY])" href="payment-receipts-list"><mat-icon>receipt</mat-icon> Comprobantes</a>
      <a mat-list-item *ngIf="showMenuByProfile([userProfiles.TREASURY, userProfiles.SCHOOL, userProfiles.SECRETARY])" href="students-list"><mat-icon>people</mat-icon> Alumnos</a>
      <a mat-list-item *ngIf="showMenuByProfile([userProfiles.STUDENT])" href="student-courses"><mat-icon>book</mat-icon> Mis Cursos</a>
      <a mat-list-item *ngIf="showMenuByProfile([userProfiles.STUDENT])" href="person-receipts-list"><mat-icon>receipt</mat-icon> Mis Comprobantes</a>
      <a mat-list-item *ngIf="showMenuByProfile([userProfiles.STUDENT])" href="user-account"><mat-icon>account_circle</mat-icon> Mis Datos</a>
      <mat-divider *ngIf="showMenuByProfile([userProfiles.STUDENT])"></mat-divider>
      <a mat-list-item *ngIf="showMenuByProfile([userProfiles.TREASURY, userProfiles.SCHOOL])" href="courses-report"><mat-icon>summarize</mat-icon> Reporte por curso</a>
      <a mat-list-item *ngIf="showMenuByProfile([userProfiles.SCHOOL, userProfiles.SECRETARY])" href="change-profile-requests"><mat-icon>how_to_reg</mat-icon> Solicitudes</a>
      <a mat-list-item *ngIf="showMenuByProfile([userProfiles.SECRETARY])" href="credential-type-manager"><mat-icon>build</mat-icon> Gestión de cargos</a>
      <a mat-list-item *ngIf="showMenuByProfile([userProfiles.STUDENT])"
        href="payments-information"><mat-icon>credit_card</mat-icon> Medios de Pago</a>
      <a mat-list-item *ngIf="showMenuByProfile([userProfiles.STUDENT])" href="contact-information"><mat-icon>phone</mat-icon>
        Datos de contacto</a>
      <a mat-list-item *ngIf="(useCredential$ | async) && showMenuByProfile([userProfiles.STUDENT])" href="user-credential" target="_blank"><mat-icon>contact_mail</mat-icon> Credenciales</a>
      <mat-divider *ngIf="(usePayslips$ | async)"></mat-divider>
      <a mat-list-item *ngIf="showMenuByProfile([userProfiles.ADMIN])" href="payslips-periods"><mat-icon>date_range</mat-icon> Períodos</a>
      <a mat-list-item *ngIf="showMenuByProfile([userProfiles.ADMIN]) || (usePayslips$ | async)" href="payslips"><mat-icon>feed</mat-icon> Recibos de sueldo</a>
    </mat-nav-list>
    <!-- <mat-card class="closed-enrollments-alert">
      <mat-icon class="closed-enrollments-alert-icon">notification_important</mat-icon> A partir del mes de <b>AGOSTO</b> sera necesario tener actualizada la contraseña de tu cuenta.En los proximos dias te estaremos enviando un correo con los pasos a seguir.
    </mat-card> -->
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
