import { Component, OnInit } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Observable, firstValueFrom } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { Router, RouterState, NavigationEnd } from "@angular/router";
import { ApiCredentialsService } from "src/app/services/api-credentials.service";
import { UtilsService } from "src/app/services/utils.service";
import { IdentityService } from "src/app/services/identity.service";
import { UserProfilesEnum } from "src/app/enums/user-profiles-enum";

@Component({
  selector: "navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
})
export class NavigationComponent implements OnInit {
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  isLoggedIn$: Observable<boolean>;
  usePayslips$: Observable<boolean>;
  useCredential$: Observable<boolean>;
  isScanner: boolean;

  userProfiles: typeof UserProfilesEnum = UserProfilesEnum;
  loggedUser: string = "Default User";

  showNav = true;


  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private utils: UtilsService,
    private APICredentials: ApiCredentialsService,
    public identity: IdentityService
  ) {
    const state: RouterState = router.routerState;
    this.isScanner =
      state.snapshot.url === "/scanner" ||
      window.location.pathname === "/scanner";
  }

  ngOnInit(): void {
    this.isLoggedIn$ = this.identity.isLoggedIn;
    this.usePayslips$ = this.identity.getUsePayslips;
    this.useCredential$ = this.identity.getUseCredential;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showNav = !event.urlAfterRedirects.includes('user-credential');
      }
    });
    // this.usePayslips = (this.identity.getDecodedToken().use_payslips == '1') ? true : false;
    this.checkCookies();
  }

  showMenuByProfile(profiles: number[]) {
    if (this.identity.tokenExists()) {
      const loggedUser: any = this.identity.getDecodedToken();
      this.loggedUser = loggedUser.user_firstname
        ? `${loggedUser.user_firstname} ${loggedUser.user_lastname}`
        : `${loggedUser.role_type}: ${loggedUser.role_name || ""}`;
      return profiles.find((prof) => prof.toString() == loggedUser.id_profile)
        ? true
        : false;
    }

    return false;
  }

  checkCookies() {
    const expirationDate = localStorage.getItem("expired");
    if (!expirationDate) {
      console.log("Cache not setted. Adding!");
      const todayDate = new Date();
      todayDate.setHours(todayDate.getHours() + 2);
      localStorage.setItem("expired", todayDate.toISOString());
    } else if (new Date(expirationDate) < new Date()) {
      console.log("Cleared Cache!");
      this.identity.logout();
    } else {
      console.log("Correct version!");
    }
  }

  logout() {
    if (confirm("¿Desea cerrar sesión?")) {
      this.identity.logout();
    }
  }
}
