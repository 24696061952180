<mat-form-field appearance="outline" *ngIf="filterable">
  <mat-label>Filtrar</mat-label>
  <input matInput (keyup)="filter($event)" placeholder="Ingrese el término a buscar..." #input>
</mat-form-field>


<table mat-table [dataSource]="data">

  <ng-container *ngFor="let column of getColumnsByType(columnTypes.TEXT)" matColumnDef="{{column.name}}">
    <th mat-header-cell *matHeaderCellDef> {{ column.text }} </th>
    <td style="padding: 10px;" mat-cell *matCellDef="let element">{{ element[column.name]  }}</td>
  </ng-container>

  <ng-container *ngFor="let column of getColumnsByType(columnTypes.NUMBER)" matColumnDef="{{column.name}}">
    <th mat-header-cell *matHeaderCellDef> {{ column.text }} </th>
    <td style="padding: 10px;" mat-cell *matCellDef="let element">{{ element[column.name] | number:'1.0-0'  }}</td>
  </ng-container>

  <ng-container *ngFor="let column of getColumnsByType(columnTypes.DATE)" matColumnDef="{{column.name}}">
    <th mat-header-cell *matHeaderCellDef> {{ column.text }} </th>
    <td style="padding: 10px;" mat-cell *matCellDef="let element">{{ element[column.name] | date: 'dd/MM/yyyy' }}</td>
  </ng-container>

  <ng-container *ngFor="let column of getColumnsByType(columnTypes.BOOLEAN)" matColumnDef="{{column.name}}">
    <th mat-header-cell *matHeaderCellDef> {{ column.text }} </th>
    <td style="padding: 10px;" mat-cell *matCellDef="let element">
    <!-- a checkobx that indicate de column state by 1 or 0  -->
      <mat-checkbox [checked]="element[column.name] == '1'" disabled>{{element[column.name] =="1" ? 'Si' : 'No'}}
    </mat-checkbox>
    </td>
  </ng-container>

  <ng-container *ngFor="let column of getColumnsByType(columnTypes.SEMAPHORE)" matColumnDef="{{column.name}}">
    <th mat-header-cell *matHeaderCellDef> {{ column.text }} </th>
    <td style="padding: 10px;" mat-cell *matCellDef="let element">
      <!-- <mat-icon [style.color]="element[column.name].value">circle</mat-icon> -->
      <mat-chip-list>
        <mat-chip [style.backgroundColor]="element[column.name].value" selected>{{element[column.name].label}}
        </mat-chip>
      </mat-chip-list>
    </td>
  </ng-container>

  <ng-container *ngIf="showActions" matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef> Acciones </th>
    <td style="padding: 10px;" mat-cell *matCellDef="let element">
      <div style="display: flex; flex-direction: row; box-sizing: border-box;">
        <button title="Editar" mat-flat-button *ngIf="showEditButton" color="primary"
          (click)="onClickEditButton(element.id)" style="margin-right: 10px;">
          <mat-icon>edit</mat-icon>
        </button>
        <button title="Eliminar" mat-flat-button class="delete-button" *ngIf="showDeleteButton" color="warn"
          (click)="onClickDeleteButton(element.id)" style="margin-right: 10px;">
          <mat-icon>delete</mat-icon>
        </button>
        <button mat-flat-button class="details-button" *ngIf="showDetailsButton" color="primary"
          (click)="onClickDetailsButton(element.id)" style="margin-right: 10px;">
          <mat-icon>info</mat-icon> Detalles
        </button>

        <button [title]="genericButton1Title" mat-flat-button class="details-button" *ngIf="showGenericButton1"
          color="{{genericButton1.color}}" (click)="onClickGenericButton1(element.id)" style="margin-right: 10px;">
          <mat-icon>{{genericButton1.icon}}</mat-icon> {{genericButton1.text}}
        </button>

        <button [title]="genericButton2Title" mat-flat-button class="details-button" *ngIf="showGenericButton2"
          color="{{genericButton2.color}}" (click)="onClickGenericButton2(element)" style="margin-right: 10px;">
          <mat-icon>{{genericButton2.icon}}</mat-icon> {{genericButton2.text}}
        </button>

        <button [title]="genericButton3Title" mat-flat-button class="details-button" *ngIf="showGenericButton3"
          color="{{genericButton3.color}}" (click)="onClickGenericButton3(element)" style="margin-right: 10px;">
          <mat-icon>{{genericButton3.icon}}</mat-icon> {{genericButton3.text}}
        </button>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="9999" style="text-align: center;">
      No se encontraron datos.
    </td>
  </tr>
</table>

<mat-paginator [pageSize]="paginatorPageSize" [length]="rowsCount" (page)="pageChanged($event)" [pageSizeOptions]="[5, 10, 20, 50, 100, 1000]"
  showFirstLastButtons aria-label="Select page of periodic elements">
</mat-paginator>
