<div class="container"
  style="display: flex; flex-direction: row; box-sizing: border-box; place-content: stretch space-around; align-items: stretch;">
  <div class="max-size-flex">
    <h1>Recibos de sueldo - Nuevo Recibo</h1>
    <mat-card>
      <form [formGroup]="payslipForm" (ngSubmit)="save()">
        <mat-card-content>
          <div style="display: flex; flex-direction: column; box-sizing: border-box;">
            <mat-form-field style="max-width: 25%; flex: 1 1 100%; box-sizing: border-box;" appearance="outline">
              <mat-label>Período</mat-label>
              <mat-select formControlName="id_period">
                <mat-option *ngFor="let p of periods" [value]="p.id">{{ p.name }}</mat-option>
              </mat-select>
              <mat-error *ngIf="payslipForm.get('id_period')?.hasError('required')">
                Este campo es <strong>obligatorio.</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field style="max-width: 25%; flex: 1 1 100%; box-sizing: border-box;" appearance="outline">
              <mat-label>Usuario</mat-label>
              <mat-select formControlName="id_user">
                <mat-option *ngFor="let u of users" [value]="u.id">{{ u.name }}</mat-option>
              </mat-select>
              <mat-error *ngIf="payslipForm.get('id_user')?.hasError('required')">
                Este campo es <strong>obligatorio.</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field style="max-width: 50%; flex: 1 1 100%; box-sizing: border-box;" appearance="outline" class="full-width">
              <mat-label>Nombre</mat-label>
              <input matInput formControlName="name">

              <mat-error *ngIf="payslipForm.get('name')?.hasError('required')">
                Este campo es <strong>obligatorio.</strong>
              </mat-error>
            </mat-form-field>

            <file-button id="fileButton" color="primary" text="Agregar archivo" icon="add"
              (fileChanged)="onFileChanged($event)"></file-button>
            <span class="form-custom-error"
              *ngIf="payslipForm.get('file_base64')?.hasError('required') && (payslipForm.get('file_base64')?.dirty || payslipForm.get('file_base64')?.touched)">
              No se agregó ningún archivo.
            </span>
            <span class="form-custom-error" *ngIf="!payslipFileValid">
              El archivo agregado no tiene el formato correcto. Formatos permitidos: jpg, jpeg, png, pdf.
            </span>

            <div
              style="display: flex; flex-direction: row; box-sizing: border-box; place-content: stretch flex-end; align-items: stretch;">
              <button mat-flat-button type="submit" color="primary">
                Cargar
                <mat-icon>file_upload</mat-icon>
              </button>

            </div>
          </div>
        </mat-card-content>
      </form>
    </mat-card>
  </div>
</div>
