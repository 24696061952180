<div class="container container-courselist">
  <div style="max-height: 10%; flex: 1 1 100%; box-sizing: border-box; margin-bottom: 20px;">
    <section-header title="Cursos" [showNewButton]="true" (newButtonPressed)="new()"></section-header>
  </div>

  <div style="flex: 1 1 1e-9px; box-sizing: border-box;">
    <mat-card>
      <mat-card-content>
        <custom-table *ngIf="dataLoaded" [dataSource]="customTableStructure" [showActions]="true" [showEditButton]="true"
          [showDeleteButton]="true" (editButtonPressed)="goToEdit($event)" (deleteButtonPressed)="delete($event)"></custom-table>
      </mat-card-content>
    </mat-card>
  </div>
</div>
